import { _sleep } from "@/helpers/misc"

export class CanvasDownloader {
    tab
    maxWidth
    maxHeight

    constructor(tab) {
        this.tab = tab
    }

    async captureImage({ name = 'generated', bg = '#fff' }) {
        let canvas = this.tab.canvas
        let element = canvas.element
        const padding = 100
        let widthO = canvas.width
        let heightO = canvas.height
        let maxWidth = this.maxWidth = canvas.maxX - canvas.minX + padding
        let maxHeight = this.maxHeight = canvas.maxY - canvas.minY + padding

        canvas.setZoom(1)
        canvas.updateOrigin(0, 0)
        canvas.updateCanvasSize(maxWidth, maxHeight)


        // adjust item positions
        let items = this.tab.schema.schema_data.tables.concat(this.tab.schema.schema_data.notes)
        for (const item of items) {
            item.left -= canvas.minX - padding
            item.top -= canvas.minY - padding
        }

        canvas.draw(this.tab)

        await _sleep(600)

        let tempC = document.createElement('canvas')
        let ctx = tempC.getContext('2d')

        // adjust the width of the temp canvas
        let tempCWidth = maxWidth + padding
        let tempCHeight = maxHeight + padding
        const dpr = window.devicePixelRatio
        tempC.width = tempCWidth * dpr
        tempC.height = tempCHeight * dpr


        // draw background
        ctx.fillStyle = bg
        ctx.fillRect(0, 0, tempC.width, tempC.height)

        // watermark
        // ctx.globalAlpha = 0.15;
        // const logo = document.getElementById("logo-img");
        // for (let w = 0; w <= tempC.width; w += 700) {
        //     for (let h = 0; h <= tempC.height; h += 350) {
        //         ctx.drawImage(logo, w, h)
        //     }
        // }

        // draw shapes
        ctx.globalAlpha = 1;
        ctx.drawImage(element, 0, 0)

        let img = tempC.toDataURL("image/png", 1).replace("image/png", "image/octet-stream")

        canvas.updateCanvasSize(widthO, heightO)

        return img
    }

    downloadImage({ name = 'generated', bg = '#fff' }) {
        return this.captureImage({ name, bg }).then(dataUrl => {
            let link = document.createElement('a')
            link.download = name + '.png'
            link.href = dataUrl
            link.click()
        })
    }

    downloadPdf({ name = 'generated', bg = '#fff' }) {
        return this.captureImage({ name, bg }).then(async dataUrl => {
            let height = this.maxHeight
            let width = this.maxWidth
            const jsPdfLimit = 12000
            const heightRatio = jsPdfLimit / height
            const widthRatio = jsPdfLimit / width
            const ratio = Math.min(heightRatio, widthRatio)
            if (ratio < 1) {
                height = Math.round(this.maxHeight * ratio)
                width = Math.round(this.maxWidth * ratio)
            }

            const jsPDF = (await import('jspdf')).jsPDF

            let doc = new jsPDF({
                orientation: width > height ? 'landscape' : 'portrait',
                unit: 'px',
                hotfixes: ["px_scaling"],
                format: [width, height]
            })
            doc.addImage(dataUrl, 'png', 0, 0, width, height, '', 'NONE')
            doc.save(name + '.pdf')
        })
    }
}
